import {
  Object3D,
  PlaneGeometry,
  VideoTexture,
  Mesh,
  MeshStandardMaterial,
  Vector3,
} from 'three';

import { Utils } from './utils';

import AGnftFilter from './AGnftFilter';

var Utils2 = new Utils();

export default class NFTaddTJS {
  /**
   * The NFTaddTJS constuctor, you need to pass the uuid from the ARnft instance.
   * @param uuid the uuid.
   */
  constructor(uuid, scene) {
    this.scene = scene;
    this.target = window || global;
    this.uuid = uuid;
    this.names = [];
    this._filter = new AGnftFilter();
    this._oef = false;
  }

  addVideo(id, name, scale, configs, objVisibility) {
    const root = new Object3D();
    root.name = 'root-' + name;
    this.scene.add(root);
    const ARVideo = document.getElementById(id);
    const texture = new VideoTexture(ARVideo);
    const mat = new MeshStandardMaterial({ color: 0xbbbbff, map: texture });
    ARVideo.play();
    const planeGeom = new PlaneGeometry(
      configs.w,
      configs.h,
      configs.ws,
      configs.hs
    );
    const plane = new Mesh(planeGeom, mat);
    plane.scale.set(scale, scale, scale);
    this.target.addEventListener(
      'getNFTData-' + this.uuid + '-' + name,
      function (ev) {
        document.body.classList.add('start');
        var msg = ev.detail;
        plane.position.y = ((msg.height / msg.dpi) * 2.54 * 10) / 2.0;
        plane.position.x = ((msg.width / msg.dpi) * 2.54 * 10) / 2.0;
      }
    );
    root.add(plane);
    this.target.addEventListener(
      'getMatrixGL_RH-' + this.uuid + '-' + name,
      function (ev) {
        root.visible = true;
        plane.visible = true;
        if (this._oef === true) {
          let filter = [
            new Vector3(0, 0, 0),
            new Vector3(0, 0, 0),
            new Vector3(0, 0, 0),
          ];
          filter = this._filter.update(ev.detail.matrixGL_RH);
          root.position.setX(filter[0].x);
          root.position.setY(filter[0].y);
          root.position.setZ(filter[0].z);
          root.rotation.setFromVector3(filter[1]);
          root.scale.setX(filter[2].x);
          root.scale.setY(filter[2].y);
          root.scale.setZ(filter[2].z);
        } else {
          root.matrixAutoUpdate = false;
          const matrix = Utils2.interpolate(ev.detail.matrixGL_RH);
          Utils2.setMatrix(root.matrix, matrix);
        }
      }
    );
    this.target.addEventListener(
      'nftTrackingLost-' + this.uuid + '-' + name,
      function () {
        root.visible = objVisibility;
        plane.visible = objVisibility;
      }
    );
    this.names.push(name);
  }
}
