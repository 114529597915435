export function getTime() {
  return Math.floor(Date.now() / 1000);
}
const trackedMatrix = {
  // for interpolation
  delta: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  interpolated: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
};
//const interpolationFactor = 24;
const interpolationFactor = 24;

export class Utils {
  constructor() {
    this.trackedMatrix = trackedMatrix;
  }

  interpolate(world) {
    // interpolate matrix
    for (let i = 0; i < 16; i++) {
      this.trackedMatrix.delta[i] =
        world[i] - this.trackedMatrix.interpolated[i];
      this.trackedMatrix.interpolated[i] =
        this.trackedMatrix.interpolated[i] +
        this.trackedMatrix.delta[i] / interpolationFactor;
    }
    return this.trackedMatrix.interpolated;
  }

  isMobile() {
    return /Android|mobile|iPad|iPhone/i.test(navigator.userAgent);
  }

  setMatrix(matrix, value) {
    const array = [];
    for (const key in value) {
      array[key] = value[key];
    }
    if (typeof matrix.elements.set === 'function') {
      matrix.elements.set(array);
    } else {
      matrix.elements = [].slice.call(array);
    }
  }
}
