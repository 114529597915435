import { ARnft } from '@webarkit/ar-nft';
//import * as ARnftThreejs from '@webarkit/arnft-threejs';
import * as THREE from 'three';

import SceneRenderer from './SceneRenderer';

import NFTaddTJS from './NFTAddTJS';

import ZipLoader from 'zip-loader';

var loader;

// Check if HMR interface is enabled
if (module.hot) {
  // Accept hot update
  module.hot.accept();
}

export default {
  init() {
    var self = this;
    var playbutton = document.getElementById('livingposter-launch-button');
    playbutton.onclick = function () {
      self.init3();
    };
  },
  init3() {
    var self = this;
    loader = new ZipLoader('images/data5.zip');
    loader.on('load', function () {
      self.init2();
    });

    loader.load();
  },
  init2() {
    var videlem = document.createElement('video');
    videlem.setAttribute('playsinline', 'playsinline');
    videlem.setAttribute('id', 'arvideo');
    videlem.loop = true;
    var sourceMP4 = document.createElement('source');
    sourceMP4.type = 'video/mp4';
    videlem.style = 'display:none;';
    //sourceMP4.src = '/images/sintel.mp4';
    sourceMP4.src = loader.extractAsBlobUrl('waterloop.mp4', 'video/mp4');

    videlem.appendChild(sourceMP4);

    document.body.appendChild(videlem);

    videlem.play();

    ARnft.init(
      640,
      480,
      ['images/waterimage10/waterimage10'],
      ['waterimage10'],
      'images/config.json',
      true
    ).then((nft) => {
      document.addEventListener('containerEvent', function () {
        let canvas = document.getElementById('canvas');
        //let fov = (0.2 * 180) / Math.PI;
        //let ratio = window.clientWidth / window.clientHeight;
        let ratio = window.innerWidth / window.innerHeight;

        let config = {
          renderer: {
            alpha: true,
            antialias: true,
            context: null,
            precision: 'mediump',
            premultipliedAlpha: true,
            stencil: true,
            depth: true,
            logarithmicDepthBuffer: true,
          },
          camera: {
            fov: 70,
            ratio: ratio,
            near: 0.01,
            far: 8000,
          },
        };

        const sceneThreejs = new SceneRenderer(config, canvas, nft.uuid);

        sceneThreejs.initRenderer();

        /* sceneThreejs.getCamera().scale.set(4, 4, 4);

        sceneThreejs.getCamera().matrixAutoUpdate = true;
        sceneThreejs.getCamera().updateProjectionMatrix();*/

        document.addEventListener(
          'getMatrixGL_RH-' + nft.uuid + '-waterimage10',
          function () {
            console.log('this is pinball!!');
          }
        );

        let nftAddTJS = new NFTaddTJS(nft.uuid, sceneThreejs.scene);
        nftAddTJS.oef = false;

        let texture = new THREE.VideoTexture(videlem);
        texture.minFilter = THREE.LinearFilter;
        texture.magFilter = THREE.LinearFilter;
        texture.format = THREE.RGBFormat;
        // let cubeMaterial = new THREE.MeshBasicMaterial({ map: texture });

        /* var cubeGeometry = new THREE.BoxBufferGeometry(1, 1, 1);

        var mat = new THREE.MeshNormalMaterial();*/

        /* let mat = new THREE.MeshLambertMaterial({
          color: 0xff0000,
        });*/

        /*var cube = new THREE.Mesh(cubeGeometry, mat);
        cube.name = 'cube';*/
        // cube.position.z = 90;
        //cube.scale.set(180, 180, 180);

        // sceneThreejs.scene.add(cube);

        /*let boxGeom = new THREE.BoxGeometry(1, 1, 1);
        let cube = new THREE.Mesh(boxGeom, mat);
        cube.name = 'cube';
        cube.position.z = 90;
        cube.scale.set(180, 180, 180);*/

        //nftAddTJS.add(cube, 'waterimage10', true);

        // Set width, height and width and height number of segments of the PlaneGeometry.
        let imgConfig = { w: 1400, h: 1920, ws: 1, hs: 2 };
        nftAddTJS.addVideo('arvideo', 'waterimage10', 1, imgConfig, false);

        /*setTimeout(function () {
          document.body.classList.add('start');
        }, 2000);

        /* window.addEventListener(
          'getNFTData-' + nft.uuid + '-' + 'waterimage10',
          function (ev) {
            var msg = ev.detail;
            console.log('hello');
            console.log(msg);
          }
        );

        window.addEventListener(
          'getMatrixGL_RH-' + nft.uuid + '-' + 'waterimage10',
          function (ev) {
            console.log('helloooooo');
            console.log(ev.detail.matrixGL_RH);
          }
        );*/

        //videlem.play();

        var tick = function () {
          sceneThreejs.draw();
          //window.requestAnimationFrame(tick);
          //  console.log('test123');
        };

        sceneThreejs.renderer.setAnimationLoop(tick);

        //tick();
      });
    });
  },
};
