import * as THREE from 'three';
import { Utils } from './utils';

var Utils2 = new Utils();

export default class SceneRenderer {
  constructor(configData, canvasDraw, uuid) {
    this.configData = configData;
    this.uuid = uuid;
    this.target = window || global;
    this.renderer = new THREE.WebGLRenderer({
      canvas: canvasDraw,
      context: configData.renderer.context,
      alpha: configData.renderer.alpha,
      premultipliedAlpha: configData.renderer.premultipliedAlpha,
      antialias: configData.renderer.antialias,
      stencil: configData.renderer.stencil,
      precision: configData.renderer.precision,
      depth: configData.renderer.depth,
      logarithmicDepthBuffer: configData.renderer.logarithmicDepthBuffer,
    });
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.scene = new THREE.Scene();
    this.globalScene = this.scene;
    this.camera = new THREE.PerspectiveCamera(
      configData.camera.fov,
      configData.camera.ratio,
      configData.camera.near,
      configData.camera.far
    );
  }

  initRenderer() {
    this.camera.position.z = 1000;
    this.camera.matrixAutoUpdate = false;
    //this.camera.updateProjectionMatrix();
    /* this.camera.matrixAutoUpdate = false;*/
    var self = this;
    this.target.addEventListener('getProjectionMatrix', function (ev) {
      console.log('test1');
      var translation = new THREE.Vector3(),
        rotation = new THREE.Quaternion(),
        scale = new THREE.Vector3();
      self.camera.projectionMatrix.decompose(translation, rotation, scale);
      console.log(translation);
      console.log(rotation);
      console.log(scale);

      console.log(ev.detail.proj);
      Utils2.setMatrix(self.camera.projectionMatrix, ev.detail.proj);

      self.camera.far = 4000;
      self.camera.updateProjectionMatrix();
      console.log('test2');
      self.camera.projectionMatrix.decompose(translation, rotation, scale);
      console.log(translation);
      console.log(rotation);
      console.log(scale);
    });
    this.scene.add(this.camera);

    const light = new THREE.AmbientLight(0xffffff);
    this.scene.add(light);
    //var self = this;
    /* this.target.addEventListener('getWindowSize', function (_ev) {
      self.renderer.setSize(_ev.detail.sw, _ev.detail.sh);
    });*/

    this.renderer.setSize(window.innerWidth, window.innerHeight);

    const setInitRendererEvent = new CustomEvent('onInitThreejsRendering', {
      detail: {
        renderer: this.renderer,
        scene: this.scene,
        camera: this.camera,
      },
    });
    this.target.dispatchEvent(setInitRendererEvent);
  }

  draw() {
    // console.log('draw');
    this.renderer.render(this.scene, this.camera);
  }

  // getters

  getRenderer() {
    return this.renderer;
  }

  getScene() {
    return this.scene;
  }

  getCamera() {
    return this.camera;
  }

  getGlobalScene() {
    return this.globalScene;
  }

  // setters

  setRenderer(renderer) {
    this.renderer = renderer;
  }

  setScene(scene) {
    this.scene = scene;
  }

  setCamera(camera) {
    this.camera = camera;
  }

  // tick to be implemented
  /* tick () {
    this.draw()
    window.requestAnimationFrame(this.tick)
  }*/
}
