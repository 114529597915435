// import external dependencies
import 'jquery';

import livingapp from './livingapp2';

// Load Events
jQuery(document).ready(() => livingapp.init());

// Check if HMR interface is enabled
if (module.hot) {
  // Accept hot update
  module.hot.accept();
}
